



















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    const self = this;
    return {
      self: this,
      item: null,
      currentTab: "",
      permitted: false,
      tabs: {
        dummy: {
          label: {
            general: "Facebook",
            tmdt: "TMDT",
            wholesale: "Khách sỉ",
          }[this.$route.params.statisticType],
        },
        index: {
          label: "Thị trường",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "vd", "vdl", "qlk", "ksnb", "ksnbl"]);
          },
        },
        wholesale_customers: {
          label: "BXH Khách sỉ",
          condition() {
            return self.$route.params.statisticType === "wholesale" && userManager.checkRole(["kt"]);
          },
        },
        profit: {
          label: "Lợi nhuận",
          condition() {
            return userManager.checkRole(self.$route.params.statisticType === "general" ? ["kt", "tp"] : ["kt"]);
          },
        },
        profit_mkt_users: {
          label: "Lợi nhuận MKT",
          condition() {
            return userManager.checkRole(self.$route.params.statisticType === "general" ? ["kt", "tp"] : ["kt"]);
          },
        },
        shops_daily: {
          label: "Shop Daily",
          condition({ $route }) {
            return $route.params.statisticType === "tmdt" && userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "ksnb", "ksnbl"]);
          },
        },
        shops_monthly: {
          label: "Shop Monthly",
          condition({ $route }) {
            return $route.params.statisticType === "tmdt" && userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "ksnb", "ksnbl"]);
          },
        },
        shops: {
          label: "BXH Shop",
          condition({ $route }) {
            return $route.params.statisticType === "tmdt" && userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "ksnb", "ksnbl"]);
          },
        },
        teams_daily: {
          label: "Team Daily",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "ksnb", "ksnbl"]);
          },
        },
        teams_monthly: {
          label: "Team Monthly",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "ksnb", "ksnbl"]);
          },
        },
        departments_daily: {
          label: "Phòng Daily",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        mkt_users: {
          label: "BXH MKT",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "ksnb", "ksnbl"]);
          },
        },
        mkt_users_daily: {
          label: "MKT Daily",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "ksnb", "ksnbl"]);
          },
        },
        creators: {
          label: "BXH KOC",
          condition({ $route }) {
            return $route.params.statisticType === "tmdt" && userManager.checkRole(["kt", "tp", "mkt"]);
          },
        },
        shipping_companies: {
          label: "ĐVVC (Date Order)",
          condition() {
            return self.$route.params.statisticType === "general" && userManager.checkRole(["kt", "vd", "vdl"]);
          },
        },
        shipping_companies_by_dxk_time: {
          label: "ĐVVC (Date Pickup)",
          condition() {
            return self.$route.params.statisticType === "general" && userManager.checkRole(["kt", "vd", "vdl"]);
          },
        },
        shipping_company_accounts: {
          label: "Tài khoản ĐVVC",
          condition() {
            return self.$route.params.statisticType === "general" && userManager.checkRole(["kt"]);
          },
        },
        not_yet_sent_orders: {
          label: "Đơn chưa gửi đi",
          condition() {
            return userManager.checkRole(["kt", "vd", "vdl"]);
          },
        },
        product_groups: {
          label: "Nhóm SP",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "content", "design", "vd", "vdl"]);
          },
        },
        return_product_groups: {
          label: "BXH Nhóm SP Return",
          condition() {
            return userManager.checkRole(["kt", "tp", "vdl"]) || (userManager.checkRole(["mkt"]) && (userManager.getUserInfo()?.ht === "ld" || self.$route.params.statisticType === "tmdt"));
          },
        },
        to_be_sent_pcs: {
          label: "Gợi ý cut đơn",
          condition({ $route }) {
            return $route.params.statisticType === "general" && userManager.checkRole(["kt", "vdl", "vd"]);
          },
        },
      },
    };
  },
  async created() {
    this.checkPermitted();
  },
  methods: {
    checkPermitted() {
      const statisticType = this.$route.params.statisticType;
      if (statisticType.toLowerCase() === "wholesale") {
        this.permitted = userManager.checkRole(["kt"]);
        return;
      }

      if (userManager.checkRole(["ksnb"], true)) {
        this.permitted = false;
      } else if (userManager.checkRole(["ksnbl", "content", "design"], true)) {
        if (statisticType === "tmdt") {
          this.permitted = false;
          return;
        }
      }
      const userInfo = userManager.getUserInfo();
      if (userInfo.roles && (userInfo.roles.includes("mkt") || userInfo.roles.includes("rd"))) {
        if (statisticType.toLowerCase() === "tmdt") {
          if (!userInfo.mktPlatforms || !userInfo.mktPlatforms.find((mp) => ["s", "lzd", "ttshop"].includes(mp.code))) {
            this.permitted = false;
            return;
          }
        } else {
          if (!userInfo.mktPlatforms || !userInfo.mktPlatforms.find((mp) => ["fb", "tt"].includes(mp.code))) {
            this.permitted = false;
            return;
          }
        }
      }
      this.permitted = true;
    },
  },
});
